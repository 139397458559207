import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography, Tooltip } from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// routes
import { PATH_AUTH, PATH_GUEST } from '../../routes/paths';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { RegisterStudentForm } from '../../sections/auth/register';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function RegisterStudent() {
    const { method } = useAuth();
    const {translate} = useLocales();
    const smUp = useResponsive('up', 'sm');

    const mdUp = useResponsive('up', 'md');


    return (
        <Page title="Register">
            <RootStyle>
                <HeaderStyle>
                    <Logo />
                    {smUp && (
                        <Typography variant="body2" sx={{ mt: { md: -2 } }} align="right">
                            {translate('auth.haveAccount')}{' '}
                            <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.login}>
                                {translate('auth.login')}
                            </Link>
                            <><br /></>
                            {translate('auth.areYouTeacher')} {''}
                            <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                                {translate('auth.registerTeacher')}
                            </Link>
                        </Typography>
                    )}
                </HeaderStyle>

                {mdUp && (
                    <SectionStyle>
                        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                        {translate('auth.learnMessage')}
                        </Typography>
                        {/* <Image
                            visibleByDefault
                            disabledEffect
                            alt="register"
                            src="https://minimals.cc/assets/illustrations/illustration_register.png"
                        /> */}
                    </SectionStyle>
                )}

                <Container>
                    <ContentStyle>
                        <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="h4" gutterBottom>
                                    {translate('auth.registerTitle')}
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{translate('auth.registerDescription')}</Typography>
                            </Box>
                            {/* <Tooltip title={capitalCase(method)}>
                <>
                  <Image
                    disabledEffect
                    src={`https://minimal-assets-api.vercel.app/assets/icons/auth/ic_${method}.png`}
                    sx={{ width: 32, height: 32 }}
                  />
                </>
              </Tooltip> */}
                        </Box>

                        <RegisterStudentForm />

                        <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
                        {translate('auth.registrationMessage')}&nbsp;
                            <Link underline="always" color="text.primary" href="https://github.com/lpagn/snowmatchfiles/blob/main/Snow%20Match%20Terms%20of%20Service.pdf">
                                {translate('auth.terms')}&nbsp;
                            </Link>
                            {translate('auth.and')}&nbsp;
                            <Link underline="always" color="text.primary" href="https://github.com/lpagn/snowmatchfiles/blob/main/Snow%20Match%20Privacy%20Policy.pdf">
                            {translate('auth.privacy')}
                            </Link>
                            .
                        </Typography>

                        {!smUp && (
                            <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                                {translate('auth.haveAccount')}{' '}
                                <Link variant="subtitle2" to={PATH_AUTH.login} component={RouterLink}>
                                {translate('auth.login')}
                                </Link>
                            </Typography>
                        )}
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    );
}
