import { Button } from '@mui/material';

import { styled } from '@mui/material/styles';



const HoverButton = styled(Button)`
  &:hover {
    color: #3399FF;
  }

`;

export default HoverButton