import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import teacherReducer from './slices/teachers';
import notificationReducer from './slices/notifications';
import adminReducer from './slices/admin'
import clientReducer from './slices/clients';
import contactReducer from './slices/contact';
import discountReducer from './slices/discount';
import businessReducer from './slices/business';
import rateReducer from './slices/rates';
import configReducer from './slices/config';
import bookingReducer from './slices/bookings';


// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  teachers: teacherReducer,
  notifications: notificationReducer,
  admin: adminReducer,
  clients: clientReducer,
  contact: contactReducer,
  discounts: discountReducer,
  business: businessReducer,
  rates: rateReducer,
  config: configReducer,
  bookings: bookingReducer,
});

export { rootPersistConfig, rootReducer };