import * as Yup from 'yup';
import { useState } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Alert, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFEditor, RHFRadioGroup, RHFSelect, RHFTextField, RHFUploadMultiFile, RHFUploadSingleFile } from '../../../components/hook-form';

//mock
import { countries } from "src/_mock"
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const { register } = useAuth();
  const {translate} = useLocales();
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string()
      .transform((value, originalValue) => originalValue.toLowerCase())
      .email('Email must be a valid email address')
      .required('Email is required'),
    cellphone: Yup.string().required('Phone number required').matches(
      "^[0-9]{10}$",
      "Phone number is not valid"
    ),
    countryCode: Yup.string().required(),
    password: Yup.string().required('Password is required'),
    certificate: Yup.mixed().required('Certification File is required',(value) => value !== '')
  });

  const [defaultValues, setDefaultValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    countryCode: '54',
    cellphone: '',
    entity: 'AADIDESS',
    certificate: null
  });

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'certificate',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const onSubmit = async (data) => {

    try {
      await register(data.email, data.password, data.firstName, data.lastName, data.countryCode, data.cellphone, data.entity, data.certificate);

    } catch (error) {
      if (error.messages && error.messages.entry){
        error.messages.entry.forEach(e => {
          setError(e.key, { type: "server", message: e.value });
        })
      } else{
        console.error("Unexpected Error:", error)
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label={translate('registerForm.name')} />
          <RHFTextField name="lastName" label={translate('registerForm.lastName')} />
        </Stack>

        <RHFTextField name="email" label={translate('registerForm.email')} />
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFSelect name="countryCode" label={translate('registerForm.countryCode')} placeholder="Country Code">
            <option value="" />
            {countries.map((option) => (
              <option key={option.code} value={option.phone}>
                {option.label} (+{option.phone})
              </option>
            ))}
          </RHFSelect>
          <RHFTextField name="cellphone" label={translate('registerForm.phone')} />
        </Stack>
        

        <RHFTextField
          name="password"
          label={translate('registerForm.password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography variant="subtitle1">{translate('registerForm.certification')}</Typography>
        <RHFRadioGroup name='entity' options={["AADIDESS", "PSIA", "ENISSCHAG"]}/>
        <RHFUploadSingleFile name="certificate" accept="image/*" maxSize={16000000} onDrop={handleDrop}/>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{':hover':{color:'#3399FF'}}}>
          {translate('registerForm.register')}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
