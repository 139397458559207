export const SKI_RESORTS = [
    { title: "Aconcagua", category: "Argentina" },
    { title: "Batea Mahuida", category: "Argentina" },
    { title: "Calafate Mountain Park", category: "Argentina" },
    { title: "Caviahue", category: "Argentina" },
    { title: "Cerro Bayo", category: "Argentina" },
    { title: "Cerro Castor", category: "Argentina" },
    { title: "Cerro Catedral", category: "Argentina" },
    { title: "Chapelco", category: "Argentina" },
    { title: "La Hoya", category: "Argentina" },
    { title: "Las Leñas", category: "Argentina" },
    { title: "Las Pendientes", category: "Argentina" },
    { title: "Lago Hermoso", category: "Argentina" },
    { title: "Los Penitentes", category: "Argentina" },
    { title: "Los Puquios", category: "Argentina" },
    { title: "Monte Bianco", category: "Argentina" },
    { title: "Patagonia Heliski", category: "Argentina" },
    { title: "Perito Moreno", category: "Argentina" },
    { title: "Vallecitos", category: "Argentina" },
    { title: "Buttermilk", category: "Aspen" },
    { title: "Highlands", category: "Aspen" },
    { title: "Snowmass", category: "Aspen" },
    { title: "Vail", category: "Vail" },
    { title: "Beaver Creek", category: "Vail" },
    { title: "Breckenridge", category: "Vail" },
    { title: "Keystone", category: "Vail" },
    { title: "Park City", category: "Vail" },
    { title: "Heavenly", category: "Vail" },
    { title: "Northstar", category: "Vail" },
    { title: "Kirkwood", category: "Vail" },
]